// REACT
import React from "react"
// MUI
import { Grid, Typography } from "@material-ui/core"
// GATSBY
import { useStaticQuery, graphql } from "gatsby"
// COMPONENTS
import Layout from "../components/layout"
import { PageTitle } from "../components/pageTitle"
import { ImageWithCaption } from "../components/imageWithCaption"
import { Link } from "../components/link"

// COMPONENT FUNCTION
const AlcarBoatsPage = () => {
  // IMAGES
  const data = useStaticQuery(graphql`
    query {
      alcarBoatsLogo: file(relativePath: { eq: "alcar-boats-logo.jpg" }) {
        ...BsmFluidImage
      }
      alcarBoatsFisherman02: file(
        relativePath: { eq: "alcar-boats-fisherman-02.jpg" }
      ) {
        ...BsmFluidImage
      }
    }
  `)

  // COMPONENT
  return (
    <Layout title="Alcar Boats">
      <PageTitle title="ALCAR BOATS" />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          <Typography paragraph>
            Folks from Cape Cod to Maine know that when the skies turn gray and
            a Nor’easter begins to blow, they’ve got to have a boat they can
            rely on to get safely back to port. Alcar boats are ready to go,
            whether it’s an afternoon outing with the family aboard an Alcar
            16’, or a weeklong fishing tournament on a 21’ Fisherman. That’s why
            we say they’re user-friendly; no rugs, no fancy wood trim, but
            plenty of stainless steel for easy cleaning. Rinse it down and its
            ready for the next trip.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <ImageWithCaption
            fluid={data.alcarBoatsLogo.childImageSharp.fluid}
            caption="Alcar Boats Logo"
            maxWidth={400}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <ImageWithCaption
            fluid={data.alcarBoatsFisherman02.childImageSharp.fluid}
            caption="19' Alcar boat in the water"
            displayCaption
            maxWidth={400}
          />
        </Grid>
        <Grid item xs={12} sm={7}>
          <Typography paragraph>
            Every boat is built with quality, dependability, and seaworthiness
            in mind. In short, they’re built “marine tough.” Each boat is built
            to suit its application on a custom basis at production line
            affordability.
          </Typography>
          <Typography paragraph>
            Check out some of our most popular configurations including the{" "}
            <Link to="/alcar-boats-classic">Classic</Link> and{" "}
            <Link to="/alcar-boats-fisherman">Fisherman</Link>. We also have a
            line of Alcar <Link to="/dinghies">dinghies</Link>. But remember,
            the best thing about an Alcar is that it can be customized to meet
            your unique needs.
          </Typography>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default AlcarBoatsPage
